$(document).on("change", "select#filter_tasmota_devices", function(event){
    fireLocationValue(event, 'filter')
});

$(document).on("click", "#tasmota_devices_item", function(event) {
    if (event.target.id === "tasmota_devices_item")
        openEdit("tasmota_devices", event)
});

function tasmota_refresh_all() {
    let daten = $(".tasmota_devices_details")
    for (let i = 0; i < daten.length; i++) {
        tasmota_refresh(daten[i].dataset.id)
    }
}
window.tasmota_refresh_all = tasmota_refresh_all

function tasmota_refresh(id, command = "", state = "") {
    let element_id = "tasmota_device_details_"+id
    $("#"+element_id)[0].innerHTML = "Please wait..."
    $.ajax({
        url:  "/tasmota_devices/"+id+"/data",
        type: "GET",
        data: {
            command: command,
            state: state
        }})
        .done(function(data) {
            $("#"+element_id)[0].innerHTML = data
        })
        .fail(function() {
            $("#"+element_id)[0].innerHTML = "unknown"
        });
}
window.tasmota_refresh = tasmota_refresh

$(document).on("click", ".tasmota_device_details_button", function(event) {
    event.preventDefault();
    tasmota_refresh(event.target.dataset.id)
});

$(document).on("change", "input.devicePowerSwitch", function(event) {
    event.preventDefault()
    event.stopPropagation()
    tasmota_refresh(event.target.parentElement.dataset.id,
        event.target.parentElement.dataset.cmd,
        event.target.parentElement.dataset.state)
});

// $(document).on("change", "RENAMED_select#filter_date_range_choose", function(event){
//     let key = $('#filter_date_range_choose')[0].value
//     let since = $('#filter_date_since')[0]
//     let until = $('#filter_date_until')[0]
//     let new_since = ''
//     let new_until = ''
//     let number = 0
//     let type = 'days'
//
//     if (key === 'nil') {
//         new_since = ''
//     } else {
//         number = parseInt(key.split('_')[0])
//         type = key.split('_')[1]
//         new_since = new Date();
//         if (type === 'days') {
//             new_since.addDays(number * -1)
//             new_since.setHours(0, 0, 0, 0);
//         }
//         if (type === 'hours') {
//             new_since.addHours(number * -1)
//         }
//         new_since = new_since.toISOLocal().substring(0,16)
//     }
//
//     since.value = new_since
//     until.value = new_until
// });

