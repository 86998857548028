// Date Enhancements

Date.prototype.getCalenderWeek = function() {
    // return $.datepicker.iso8601Week(this);
    let time,
        checkDate = new Date( this.getTime() );

    // Find Thursday of this week starting on Monday
    checkDate.setDate( checkDate.getDate() + 4 - ( checkDate.getDay() || 7 ) );

    time = checkDate.getTime();
    checkDate.setMonth( 0 ); // Compare with Jan 1
    checkDate.setDate( 1 );
    return Math.floor( Math.round( ( time - checkDate ) / 86400000 ) / 7 ) + 1;
}

Date.prototype.getTimeStr = function() {
    return (this.getHours() < 10 ? '0' : '') + this.getHours()+':'+
        (this.getMinutes() < 10 ? '0' : '') + this.getMinutes();
};

Date.prototype.addSeconds = function(seconds) {
    this.setSeconds(this.getSeconds() + seconds);
    return this;
};

Date.prototype.addMinutes = function(minutes) {
    this.setMinutes(this.getMinutes() + minutes);
    return this;
};

Date.prototype.addHours = function(hours) {
    this.setHours(this.getHours() + hours);
    return this;
};

Date.prototype.addDays = function(days) {
    this.setDate(this.getDate() + days);
    return this;
};

Date.prototype.addWeeks = function(weeks) {
    this.addDays(weeks*7);
    return this;
};

Date.prototype.addMonths = function (months) {
    var dt = this.getDate();
    this.setMonth(this.getMonth() + months);
    var currDt = this.getDate();
    if (dt !== currDt) {
        this.addDays(-currDt);
    }
    return this;
};

Date.prototype.addYears = function(years) {
    var dt = this.getDate();
    this.setFullYear(this.getFullYear() + years);
    var currDt = this.getDate();
    if (dt !== currDt) {
        this.addDays(-currDt);
    }
    return this;
};

Date.prototype.toISOLocal = function() {
    var z  = n =>  ('0' + n).slice(-2);
    var zz = n => ('00' + n).slice(-3);
    var off = this.getTimezoneOffset();
    var sign = off > 0? '-' : '+';
    off = Math.abs(off);

    return this.getFullYear() + '-'
        + z(this.getMonth()+1) + '-' +
        z(this.getDate()) + 'T' +
        z(this.getHours()) + ':'  +
        z(this.getMinutes()) + ':' +
        z(this.getSeconds()) + '.' +
        zz(this.getMilliseconds()) +
        sign + z(off/60|0) + ':' + z(off%60);
}
