
let jsonFormatterSettingKeys = ['source']

window.addEventListener("turbolinks:load", function() {
    if (isControllerAction("pages", "json_formatter")) jsonFormatterSettingsLoad()
});

function jsonFormatterSettingsLoad() {
    for (let setting of jsonFormatterSettingKeys) {
        jsonFormatterSettingLoad(setting)
    }
    updateJsonFormats()
}
window.jsonFormatterSettingsLoad = jsonFormatterSettingsLoad

function jsonFormatterSettingLoad(name, elementPrefix = 'json_') {
    let val = window.localStorage.getItem(elementPrefix+name)
    if (val) {
        $('#'+elementPrefix+name).val(val)
    }
}

function jsonFormatterSettingsSave() {
    for (let setting of jsonFormatterSettingKeys) {
        jsonFormatterSettingSave(setting)
    }
}

function jsonFormatterSettingSave(name, elementPrefix = 'json_') {
    window.localStorage.setItem(elementPrefix+name, $('#'+elementPrefix+name).val())
}

function updateJsonFormats() {
    // console.log("updateJsonFormats", data)
    let sourceData = $('#json_source').val()
    jsonFormatterSettingsSave()
    $.ajax({
        url:  "/pages/json_formatter.json",
        type: "GET",
        data: {
            json_source: sourceData
        }})
        .done(function(data) {
            // console.dir(data)
            if (data.is_error) {
                $("#json_error")[0].innerHTML = data.error_message
            } else {
                $("#json_formatted")[0].value = data.json_formatted
                $("#json_error")[0].innerHTML = data.error_message
            }
        });
    return false;
}

$(document).on("input", "#json_source", function() {
    updateJsonFormats()
});
