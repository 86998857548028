
function updateDateFormats(event, date, date_type) {
    //console.log("updateDateFormats", date, date_type)
    $.ajax({
        url:  "/pages/date_formatted",
        type: "GET",
        data: {
            date: date,
            date_type: date_type
        }})
        .done(function(data) {
            if (date_type !== "locale") $("#dates_locale")[0].value = data.locale
            if (date_type !== "utc") $("#dates_utc")[0].value = data.utc
            if (date_type !== "unix") $("#dates_unix")[0].value = data.unix
            if (date_type !== "delphi") $("#dates_delphi")[0].value = data.delphi
            if (date_type !== "object_script") $("#dates_object_script")[0].value = data.object_script
            if (date_type !== "stardate") $("#dates_stardate")[0].value = data.stardate
            let range = $("#dates_object_script_range")[0]
            if (data.object_script_valid) {
                range.classList.add("invisible")
            } else {
                range.classList.remove("invisible")
            }
        })
        .fail(function() {
            if (date_type !== "locale") $("#dates_locale")[0].value = "N/A"
            if (date_type !== "utc") $("#dates_utc")[0].value = "N/A"
            if (date_type !== "unix") $("#dates_unix")[0].value = "N/A"
            if (date_type !== "delphi") $("#dates_delphi")[0].value = "N/A"
            if (date_type !== "object_script") $("#dates_object_script")[0].value = "N/A"
            if (date_type !== "stardate") $("#dates_stardate")[0].value = "N/A"
            $("#dates_object_script_range")[0].classList.add("invisible")
        });
    return false;
}

$(document).on("input", "#dates_locale", function(event) {
    updateDateFormats(event, event.currentTarget.value, "locale")
});
$(document).on("input", "#dates_utc", function(event) {
    updateDateFormats(event, event.currentTarget.value, "utc")
});
$(document).on("input", "#dates_unix", function(event) {
    updateDateFormats(event, event.currentTarget.value, "unix")
});
$(document).on("input", "#dates_delphi", function(event) {
    updateDateFormats(event, event.currentTarget.value, "delphi")
});
$(document).on("input", "#dates_object_script", function(event) {
    updateDateFormats(event, event.currentTarget.value, "object_script")
});
$(document).on("input", "#dates_stardate", function(event) {
    updateDateFormats(event, event.currentTarget.value, "stardate")
});

function refreshDiffs(preserveMinutes, preserveDecimal) {
    let start = $("#dates_time_start")[0].value.timeToMinutes()
    let end = $("#dates_time_end")[0].value.timeToMinutes()
    let diff, diffDec
    let sum, sumDec
    if (!isNaN(start)) {
        if (!preserveMinutes) $("#dates_time_start_minutes")[0].value = start
        if (!preserveDecimal) $("#dates_time_start_decimal")[0].value = start/60
    }
    if (!isNaN(end)) {
        if (!preserveMinutes) $("#dates_time_end_minutes")[0].value = end
        if (!preserveDecimal) $("#dates_time_end_decimal")[0].value = end/60
    }
    if (isNaN(start) || isNaN(end)) {
        diff = ''
        diffDec = ''
        sum = ''
        sumDec = ''
    } else {
        diff = end-start
        sum = start+end
        diffDec = diff/60
        sumDec = sum/60
    }
    $("#dates_time_diff")[0].value = diff
    $("#dates_time_diff_decimal")[0].value = diffDec
    $("#dates_time_sum")[0].value = sum
    $("#dates_time_sum_decimal")[0].value = sumDec
}
$(document).on("input", "#dates_time_start", function() {
    refreshDiffs(false, false)
});
$(document).on("input", "#dates_time_end", function() {
    refreshDiffs(false, false)
});

function refreshDiffsByMinutes(minutes, destination, preserveMinutes, preserveDecimal) {
    let min
    let hour

    if (!isNaN(minutes)) {
        hour = (Math.floor(minutes / 60)+'').padStart(2, '0')
        min = ((minutes % 60)+'').padStart(2, '0')
        if ($(destination)[0].value !== hour+':'+min) {
            $(destination)[0].value = hour+':'+min
            refreshDiffs(preserveMinutes, preserveDecimal)
        }
    }
}
$(document).on("input", "#dates_time_start_minutes", function() {
    refreshDiffsByMinutes(parseInt($('#dates_time_start_minutes')[0].value), '#dates_time_start', true, false)
});
$(document).on("input", "#dates_time_end_minutes", function() {
    refreshDiffsByMinutes(parseInt($('#dates_time_end_minutes')[0].value), '#dates_time_end', true, false)
});

function refreshDiffsByFloat(float, destination) {
    if (!isNaN(float)) {
        refreshDiffsByMinutes(Math.round(float * 60), destination, false, true)
    }
}
$(document).on("input", "#dates_time_start_decimal", function() {
    refreshDiffsByFloat($('#dates_time_start_decimal')[0].value, '#dates_time_start')
});
$(document).on("input", "#dates_time_end_decimal", function() {
    refreshDiffsByFloat($('#dates_time_end_decimal')[0].value, '#dates_time_end')
});
