
Number.prototype.minutesToDecimal = function() {
    return decimalTime = this / 60;
}

Number.prototype.minutesToTime = function() {
    const hours = Math.floor(this / 60);
    const remainingMinutes = this % 60;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes;

    return `${formattedHours}:${formattedMinutes}`;
}
