import { format } from 'sql-formatter';

let sqlFormatterSettingKeys = ['source', 'language', 'tabWidth', 'keywordCase', 'multilineLists']

window.addEventListener("turbolinks:load", function() {
    if (isControllerAction("pages", "sql_formatter")) sqlFormatterSettingsLoad()
});

function sqlFormatterSettingsLoad() {
    for (let setting of sqlFormatterSettingKeys) {
        sqlFormatterSettingLoad(setting)
    }
    updateSqlFormats()
}
window.sqlFormatterSettingsLoad = sqlFormatterSettingsLoad

function sqlFormatterSettingLoad(name, elementPrefix = 'setting') {
    // window.localStorage.removeItem('setting_'+name)
    let val = window.localStorage.getItem('setting_'+name)
    if (val) {
        $('#'+elementPrefix+'_'+name).val(val)
    }
}

function sqlFormatterSettingsSave() {
    for (let setting of sqlFormatterSettingKeys) {
        sqlFormatterSettingSave(setting)
    }
}

function sqlFormatterSettingSave(name, elementPrefix = 'setting') {
    window.localStorage.setItem('setting_'+name, $('#'+elementPrefix+'_'+name).val())
}

function getSqlFormatterSettings() {
    let result = {}
    for (let setting of sqlFormatterSettingKeys) {
        result[setting] = $('#setting_'+setting).val()
    }
    if (result['language'] === 'iris') {
        result['language'] = 'sql'
        result['isIris'] = true
    }
    return result
}

function updateSqlFormats() {
    //https://github.com/zeroturnaround/sql-formatter/tree/master/docs
    let source = $('#setting_source').val();
    let settings = getSqlFormatterSettings()

    $("#sql_formatted")[0].value = modifyFormatted(settings, format(source, settings))
    sqlFormatterSettingsSave()
}

function modifyFormatted(settings, formatted) {
    if (settings['isIris']) {
        return modifyFormattedIris(formatted)
    }
    return formatted
}

function modifyFormattedIris(formatted) {
    let result = formatted
    result = result.replaceAll(" - > ", "->")
    result = result.replaceAll(" % ", " %")
    return result
}

$(document).on("input", "#setting_source", function(event) {
    setTimeout(updateSqlFormats, 1000)
});
$(document).on("change", "#setting_language", function(event) {
    updateSqlFormats()
});
$(document).on("change", "#setting_keywordCase", function(event) {
    updateSqlFormats()
});
$(document).on("change", "#setting_tabWidth", function(event) {
    updateSqlFormats()
});
$(document).on("change", "#setting_multilineLists", function(event) {
    updateSqlFormats()
});
