$(document).on("change", ".working_day_details_duration", refreshDetailsSummary);
$(document).on("change", ".working_day_details_checked", refreshDetailsSummary);
$(document).on("change", ".working_day_details_ignore", refreshDetailsSummary);
$(document).on("cocoon:after-remove", "#working_day_details", refreshDetailsSummary);

function calcWorkingDayDetailsDuration(event) {
    const from = event.currentTarget.parentElement.parentElement.querySelector("input[id*=from]")
    const until = event.currentTarget.parentElement.parentElement.querySelector("input[id*=until]")

    if (!from || !until) return; // Early return if either input is missing

    const dec_from = from.value?.timeToMinutes() || $('#work_from-input').val().timeToMinutes();
    const dec_until = until.value?.timeToMinutes() || $('#work_until-input').val().timeToMinutes();
    const diff = Math.abs(dec_until-dec_from)

    event.currentTarget.value = diff.minutesToDecimal().toFixed(2)
}
window.calcWorkingDayDetailsDuration = calcWorkingDayDetailsDuration
$(document).on("dblclick", ".working_day_details_duration", calcWorkingDayDetailsDuration);

function setWorkingDayDetailsDescription(event) {
    let url
    let shift_key_pressed = event.shiftKey
    let alt_key_pressed = event.altKey
    let id = parseInt(event.currentTarget.value)
    let gitlab_project_element = event.currentTarget.parentElement.parentElement.querySelector("select[id*=gitlab_project]")
    let desc_field = event.currentTarget.parentElement.parentElement.querySelector("input[id*=description]")
    let company_id = $("#company_id-input")[0].value
    let gitlab_project = ''
    if (gitlab_project_element) {
        gitlab_project = gitlab_project_element.value
    }

    if (isNaN(id)) return
    if (!shift_key_pressed && (desc_field.value !== '')) return

    if (alt_key_pressed) {
        if (gitlab_project.length === 0) {
            url = `/redmine/issues/${id}`
        } else {
            url = `/gitlab/project/${gitlab_project}/issue/${id}`
        }
        $.ajax({
            url: url,
            type: "GET",
            data: {
                company_id: company_id,
                gitlab_project: gitlab_project
            }
        })
            .done(function (data) {
                if (data !== null) {
                    desc_field.value = data.subject.trim()
                    if (data.project.name) {
                        desc_field.value = data.project.name.split('-').pop().trim()+': '+desc_field.value
                    }
                }
            });
    } else {
        $.ajax({
            url: `/working_day_details/description/${company_id}/${id}`,
            type: "GET",
            data: {
                gitlab_project: gitlab_project
            }
        })
            .done(function (data) {
                if ((data !== null) && (data !== "")) {
                    desc_field.value = data
                }
            });
    }
}
window.setWorkingDayDetailsDescription = setWorkingDayDetailsDescription
$(document).on("dblclick", ".working_day_details_ticket_no", setWorkingDayDetailsDescription);
