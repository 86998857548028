// String Enhancements

String.prototype.copyToClipboard = function() {
    if (this) {
        if (window.navigator.clipboard) {
            return window.navigator.clipboard.writeText(this);
        } else if (window.clipboardData && window.clipboardData.setData) {
            return window.clipboardData.setData('Text', this);
        } else {
            var textarea = document.createElement("textarea");
            textarea.textContent = this;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            }
            catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return prompt("Copy to clipboard: Ctrl+C, Enter", text);
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
    }
};

String.prototype.timeToMinutes = function() {
    let arr = this.split(':')
    return parseInt(arr[0], 10)*60 + parseInt(arr[1], 10)
};

String.prototype.timeToDecimal = function() {
    const [hours, minutes] = this.split(':');
    const decimalHours = hours / 24;
    const decimalMinutes = minutes / 60;
    const decimalTime = decimalHours + decimalMinutes;

    return decimalTime
}
