
$(document).on("click", ".edit-time-entry", function(event) {
    let issue_id = event.currentTarget.parentElement.parentElement.querySelector("input[id*=ticket_no]").value
    let gitlab_project_element = event.currentTarget.parentElement.parentElement.querySelector("select[id*=gitlab_project]")
    let gitlab_project = ''
    if (gitlab_project_element) {
        gitlab_project = gitlab_project_element.value
    }
    let issue_data = getJsonOfTickets(gitlab_project, issue_id)

    $.ajax({
        url:  "/redmine/edit_time_entry",
        type: "GET",
        data: {
            work_day: $("#work_day-input")[0].value,
            company_id: $("#company_id-input")[0].value,
            issue_id: issue_id,
            gitlab_project: gitlab_project,
            issue_data: issue_data
        }})
        .done(function(response) {
            $('.modal-body').html(response);
            $('#mainModal').modal('show');
        });
});

function getJsonOfTicket(gitlab_project, ticketNo, row) {
    let json
    let line_ticket_no
    let line_gitlab_project
    let line_gitlab_project_element
    let valid

    line_ticket_no = row.querySelector("input[id*=ticket_no]").value
    line_gitlab_project_element = row.querySelector("select[id*=gitlab_project]")
    line_gitlab_project = ''
    if (line_gitlab_project_element) {
        line_gitlab_project = line_gitlab_project_element.value
    }
    valid = (ticketNo === line_ticket_no)
    if (gitlab_project.length !== 0) {
        valid = valid && (line_gitlab_project === gitlab_project)
    } else {
        valid = valid && (line_gitlab_project.length === 0)
    }
    if (valid) {
        json = {
            duration: row.querySelector("input[id*=duration]").value,
            description: row.querySelector("input[id*=description]").value
        }
    }
    return json
}
function getJsonOfTickets(gitlab_project, ticketNo) {
    let tableRows
    let current
    let result

    result = []
    tableRows = document.querySelectorAll(".wdd_details")
    for (let i = 0; i < tableRows.length; i++) {
        current = getJsonOfTicket(gitlab_project, ticketNo, tableRows[i])
        if (current !== undefined) {
            result.push(current)
        }
    }
    return result
}

$(document).on("click", "#create_time_entry", function(event) {
    $("#mainModal").modal('hide')
    setTimeout(fetchRedmineSum, 500);
});

function fetchRedmineSum() {
    $.ajax({
        url:  "/redmine/get_time_entry_sum_day",
        type: "GET",
        data: {
            work_day: $("#work_day-input")[0].value,
            company_id: $("#company_id-input")[0].value
        }})
        .done(function(response) {
            let redmine_sum = response.sum
            let day_sum = $('#day_sum')[0].innerText

            $("#redmine_sum")[0].innerText = redmine_sum.toFixed(2)
            $("#redmine_sum_diff")[0].innerText = (redmine_sum-day_sum).toFixed(2)
        });
}
window.fetchRedmineSum = fetchRedmineSum
