// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('data-confirm-modal')
require("chartkick/chart.js")
require("trix")
require("@rails/actiontext")
require("jquery-ui")
require("bootstrap-tokenfield/dist/bootstrap-tokenfield.js")
require("bootstrap-tokenfield/dist/css/bootstrap-tokenfield.min.css")
import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")
require("@nathanvda/cocoon")

import Rails from '@rails/ujs';
Rails.start();
import 'bootstrap'
import './modal'
import './date_enhancement'
import './string_enhancement'
import './number_enhancement'
import './date_formats'
import './json_formatter'
import './sql_formatter'
import './working_days'
import './working_day_details_fields'
import './tasmota_devices'
import './blood_pressure_values'
import './redmine/time_entries'

window.addEventListener("turbolinks:load", function(){
    $("[data-behaviour='tokenfield']").tokenfield()
});

window.addEventListener("turbolinks:load", function(){
    flatpickr("[data-behaviour='flatpickr']"), {
        dateFormat: "Y-m-d"
    }
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

dataConfirmModal.setDefaults({
    title: ' '
});

function fireLocation(event, name, value) {
    event.preventDefault();
    const search = new URLSearchParams(location.search);
    search.set(name, value);
    location.search = search.toString();
}
window.fireLocation = fireLocation

function fireLocationChecked(event, name) {
    fireLocation(event, name, event.target.checked)
}
window.fireLocationChecked = fireLocationChecked

function fireLocationValue(event, name) {
    fireLocation(event, name, event.target.value)
}
window.fireLocationValue = fireLocationValue

$(document).on("click", "a#alert-link", function(event) {
    event.preventDefault(); // Prevent link from following its href
    console.log('alert-link');
    let data_message = event.target.closest('a').dataset.message
    if (data_message !== undefined)
        alert(data_message)
    return false;
});

function isControllerAction(controller, action) {
    return $("."+controller+"."+action).length > 0

}
window.isControllerAction = isControllerAction

function openEdit(url, event) {
    if (event.target.closest('a')) { return }

    event.preventDefault(); // Prevent link from following its href
    let data_element = event.target.closest('tr')
    if (data_element === null)
        data_element = event.target.closest('.card')
    let data_id = data_element.dataset.id
    console.log("data_id=", data_id)
    if (data_id !== undefined)
        window.location.href = "/"+url+"/"+data_id+"/edit"
}
window.openEdit = openEdit

$(document).on("click", ".button_copy_clipboard", function(event) {
    event.preventDefault()
    event.stopPropagation()
    let src = $('#'+event.currentTarget.getAttribute("data-from"))
    if (src && (src.length > 0)) {
        src.val().copyToClipboard()
    }
});

$(document).on("click", ".button_copy_left", function(event) {
    event.preventDefault()
    event.stopPropagation()
    let src = $('#'+event.currentTarget.getAttribute("data-from"))
    let dst = $('#'+event.currentTarget.getAttribute("data-to"))
    if (src && (src.length > 0 ) && dst && (dst.length > 0)) {
        src.val(dst.val())
        src.trigger("input")
    }
});

$(document).on("click", ".button_example", function(event) {
    event.preventDefault()
    event.stopPropagation()
    let dst = $('#'+event.currentTarget.getAttribute("data-to"))
    let example = event.currentTarget.getAttribute("data-example")
    if (dst && (dst.length > 0)) {
        dst.val(example)
        dst.trigger("input")
    }
});

$(document).on("click", "#blood_pressure_value_table", function(event) {
    openEdit("blood_pressure_values", event)
});

$(document).on("click", "#working_days_table", function(event) {
    openEdit("working_days", event)
});

$(document).on("click", "#companies_table", function(event) {
    openEdit("companies", event)
});

$(document).on("click", "#knowledges_card_item", function(event) {
    openEdit("knowledges", event)
});

$(document).on("click", "#languages_table", function(event) {
    openEdit("vocabulary/languages", event)
});

$(document).on("click", "#word_categories_table", function(event) {
    openEdit("vocabulary/word_categories", event)
});
$(document).on("click", "#word_properties_table", function(event) {
    openEdit("vocabulary/word_properties", event)
});
$(document).on("click", "#words_table", function(event) {
    openEdit("vocabulary/words", event)
});
$(document).on("click", "#inventories_table", function(event) {
    openEdit("inventories", event)
});

$(document).on("click", "#stock_brokers_table", function(event) {
    openEdit("stock/brokers", event)
});
$(document).on("click", "#stock_companies_table", function(event) {
    openEdit("stock/companies", event)
});
$(document).on("click", "#stock_book_entries_table", function(event) {
    openEdit("stock/book_entries", event)
});
$(document).on("change", "select#filter_book_entries", function(event){
    fireLocationValue(event, 'filter')
});

function changeInt(event, do_plus) {
    event.preventDefault(); // Prevent link from following its href
    // console.log('click field_increment')
    let for_value = event.currentTarget.getAttribute('for')
    if (for_value !== null) {
        let for_element = $('#'+for_value)[0]
        if (for_element) {
            let old_value = parseInt(for_element.value)
            if (isNaN(old_value))
                old_value = 0
            if (do_plus)
                for_element.value = old_value+1
            else
                for_element.value = old_value-1
            const event = new Event('change', {
                view: window,
                bubbles: true,
                cancelable: true
            });
            for_element.dispatchEvent(event);
        }
    }
}

$(document).on("click", ".field_decrement", function(event) {
    changeInt(event, false)
});

$(document).on("click", ".field_increment", function(event) {
    changeInt(event, true)
});

$(document).on("click", "button#working_day_calc", function(event) {
    event.preventDefault(); // Prevent link from following its href
    $.ajax({
        url:  "/working_days_rest_of_day",
        type: "GET",
        data: {
            work_day: $('#work_day-input').val(),
            work_from: $('#work_from-input').val(),
            work_break: $('#work_break-input').val()
        },
        success: function(data) {
            let date_until = new Date(Date.parse(data))
            $('#work-until-input').val(date_until.getTimeStr())
        }
    });

    return false;
});

function scrollElementIntoView(elementClassName="navbar", behavior="auto") {
    // see previous commit, for some enhancements
    const finalOffset = window.parent.document.getElementsByClassName(elementClassName)[0].clientHeight
    window.parent.scrollTo({
        top: finalOffset,
        behavior: behavior || 'auto'
    })
}
window.scrollElementIntoView = scrollElementIntoView

$(document).on("click", "#filterButton", function(event) {
    let filter = $('#filter')[0]
    if (filter) {
        filter.classList.toggle("show")
    }
    event.currentTarget.classList.toggle('btn-success')
    event.currentTarget.classList.toggle('btn-warning')
});

$(document).on("click", "#detailsButton", function(event) {
    console.log("aa")
    let filter = $('#details')[0]
    if (filter) {
        filter.classList.toggle("show")
    }
    event.currentTarget.classList.toggle('btn-success')
    event.currentTarget.classList.toggle('btn-warning')
});
