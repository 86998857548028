$(document).on("change", "input#week_only", function(event) {
    fireLocationChecked(event, 'week_only')
});
$(document).on("change", "input#show_as_time", function(event) {
    fireLocationChecked(event, 'show_as_time')
});
$(document).on("change", "select#filter_working_days", function(event){
    fireLocationValue(event, 'filter')
});
$(document).on("change", "select#filter_work_type", function(event){
    fireLocationValue(event, 'filter_work_type')
});
$(document).on("change", "select#filter_company", function(event){
    fireLocationValue(event, 'company')
});

$(document).on("change", "input#filter_work_from", function(event) {
    fireLocationValue(event, 'filter_work_from')
});
$(document).on("change", "input#filter_work_until", function(event) {
    fireLocationValue(event, 'filter_work_until')
});

$(document).on("change", "input#work_day-input", function(event){
    let element = $('#work_day-input')
    element.attr('title', "CW "+new Date(element.val()).getCalenderWeek());
});

function checkWorkingDayVisibility() {
    // console.log("checkWorkingDayVisibility")
    let b = ($('select#work_type-input')[0].value === 'work')
    // console.log(b)
    let elements = $('.hideable')
    for (const element of elements) {
        if (b) {
            element.classList.remove('d-none')
        } else {
            element.classList.add('d-none')
        }
    }
}
window.checkWorkingDayVisibility = checkWorkingDayVisibility
$(document).on("change", "select#work_type-input", checkWorkingDayVisibility);

function refreshWorkingDayDuration() {
    let day_element = $('#day_sum')[0]
    let day_element_time = $('#day_sum_time')[0]
    let from = $('#work_from-input').val().timeToMinutes()
    let until = $('#work_until-input').val().timeToMinutes()
    let breakValue = (parseInt($('#work_break-input').val()) || 0)
    let value = (until-from-breakValue)

    if (value < 0) {
        day_element.innerText = 'Invalid'
        day_element_time.innerText = 'Invalid'
    } else {
        day_element.innerText = value.minutesToDecimal().toFixed(2)
        day_element_time.innerText = value.minutesToTime()
        refreshSummaryDiff()
    }
}
window.refreshWorkingDayDuration = refreshWorkingDayDuration
$(document).on("change", ".working-day-date", refreshWorkingDayDuration);
$(document).on("keyup", ".working-day-date", refreshWorkingDayDuration);

function refreshDetailsSummary() {
    let summary = 0
    $('#working_day_details tr').each(function(){
        let duration = parseFloat(this.querySelectorAll("input[id*=duration]")[0].value || 0)
        let destroy = this.querySelectorAll("input[id*=_destroy]")[0].value === "1"
        let checked = this.querySelectorAll("input[id*=checked]")[0].checked
        let ignore = this.querySelectorAll("input[id*=ignore]")[0].checked
        // debugger
        // console.log(">", duration, destroy, checked)
        if (!destroy && (checked || ignore)) {
            summary = summary + duration
        }
    })
    $('#details_duration_sum')[0].innerText = summary.toFixed(2)
    refreshSummaryDiff()
    // console.log("summ", summary)
}
window.refreshDetailsSummary = refreshDetailsSummary

function refreshSummaryDiff() {
    let sum_of_details = parseFloat($('#details_duration_sum')[0].innerText || 0)
    let day_sum = parseFloat($('#day_sum')[0].innerText || 10)
    $('#summary_diff')[0].innerText = (day_sum-sum_of_details).toFixed(2)
}
window.refreshSummaryDiff = refreshSummaryDiff

function checkNestedAttributes() {
    $('#working_day_details tr').each(function(){
        let destroy = this.querySelectorAll("input[id*=_destroy]")[0].value === "1"
        let link = this.querySelectorAll("a")[0]
        // console.log(">", destroy)
        if (destroy) {
            link.click();
        }
    })
}
window.checkNestedAttributes = checkNestedAttributes

function setCurrentTime(event) {
    event.preventDefault();
    let time = event.currentTarget
    let current = new Date()
    let hh = (current.getHours()+"").padStart(2, 0)
    let mm = (current.getMinutes()+"").padStart(2, 0)
    let oldValue = time.value
    time.value = hh+':'+mm
    if (oldValue !== time.value) {
        let evt = new Event('change', {"bubbles": true, "cancelable": false})
        time.dispatchEvent(evt);
    }
}
$(document).on("dblclick", "input[type='time']", setCurrentTime);

function working_day_ready() {
    checkWorkingDayVisibility();
    refreshWorkingDayDuration();
    refreshDetailsSummary();
    checkNestedAttributes();
    fetchRedmineSum();
}
window.working_day_ready = working_day_ready

window.addEventListener("turbolinks:load", function() {
    if (isControllerAction("working_days", "edit")) working_day_ready()
});